import moment from "moment";
import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { Card, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import {
  IoChevronBackOutline,
  IoTrashOutline
} from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import { getImageURL } from "../../helpers/image";
import { getHistories } from "../../services/history";
import {
  blockedUser,
  deleteSession,
  editUser,
  getSessions,
  getUser,
} from "../../services/user";
import Select from "../../components/UI/Select";

const EditUser = () => {
  const { userId } = useParams();
  const [loading, setLoading] = useState(true);
  const [blockedShow, setBlockedShow] = useState(false);
  const [modalSessionDelete, setModalSessionDelete] = useState({
    show: false,
    id: false,
  });
  const [sessions, setSessions] = useState({ loading: false, items: [] });
  const [histories, setHistories] = useState({ loading: false, items: [] });
  const [typeData, setTypeData] = useState(false);
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });
  const form = useWatch({ control });

  const {
    register: registerBlocked,
    formState: { errors: errorsBlocked, isValid: isValidBlocked },
    handleSubmit: handleSubmitBlocked,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: { id: userId },
  });

  // const sessionColumns = [
  //   {
  //     name: "Браузер",
  //     selector: "brand",
  //   },
  //   {
  //     name: "Устройство",
  //     selector: "osName",
  //   },
  //   {
  //     name: "Версия",
  //     selector: "osVersion",
  //   },
  //   {
  //     name: "IP",
  //     selector: "ip",
  //   },
  //   { name: "Последний вход", cell: (row) => moment(row.updatedAt).format("kk:mm DD.MM.YYYY") },
  //   {
  //     width: "50px",
  //     selector: "action",
  //     align: "right",
  //     cell: (row) => (
  //       <div className="d-flex align-items-center">
  //         <a
  //           onClick={() =>
  //             setModalSessionDelete({
  //               show: !modalSessionDelete.show,
  //               id: row.id,
  //             })
  //           }
  //         >
  //           <IoTrashOutline size={20} className="text-danger" />
  //         </a>
  //       </div>
  //     ),
  //   },
  // ];
  const userColumns = [
    {
      name: "Сотрудник",
      selector: "nickname",
      width: 180,
      cell: (row) => (
        <Link to={"/user/" + row.id} className="d-flex align-items-center">
          <div className="position-relative me-2">
            {(row.status === 0 || row.blockedEnd) && (
              <div className="blocked-avatar">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.32"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    fill="#FF0000"
                    d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
                  />
                  <path
                    fill="#FF0000"
                    d="M18.6038 3.01136C19.2189 2.39628 20.2161 2.39628 20.8312 3.01136C21.4463 3.62643 21.4463 4.62367 20.8312 5.23874L5.23947 20.8304C4.6244 21.4455 3.62716 21.4455 3.01209 20.8304C2.39701 20.2154 2.39701 19.2181 3.01209 18.6031L18.6038 3.01136Z"
                  />
                </svg>
              </div>
            )}
            {row?.options?.verified && (
              <HiMiniCheckCircle size={15} className="avatar-verified" />
            )}
            <img
              src={getImageURL({ path: row.media, type: "user" })}
              width={30}
              height={30}
              className="mini-img"
            />
          </div>
          <div className="text-transfer">{row.nickname}</div>
        </Link>
      ),
    },
    {
      name: "Организация",
      selector: "phone",
    },
    {
      name: "Стоимость",
      selector: "cash",
    },
    {
      name: "Файл",
      selector: "cash",
    },
    {
      name: "Дата",
      selector: "createdAt",
      cell: (row) => (
        <>
          <span className="me-1">
            {moment(row.createdAt).format("DD.MM.YYYY")}
          </span>
          <span className="fw-7">{moment(row.createdAt).format("kk:mm")}</span>
        </>
      ),
    },
  ];

  const header = useMemo(() => {
    return (
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h5>История просчётов</h5>
      </div>
    )
  })
  const getData = () => {
    getUser(userId)
      .then((res) => reset(res))
      .finally(() => setLoading(false));
  };

  const getDataSessions = () => {
    getSessions({ userId })
      .then((res) => setSessions({ items: res, loading: false }))
      .catch(() => setSessions((data) => ({ ...data, loading: false })));
  };

  const getDataHistories = () => {
    getHistories({ userId })
      .then((res) => setHistories({ loading: false, ...res }))
      .catch(() => setHistories((data) => ({ ...data, loading: false })));
  };

  const onDeleteSession = () => {
    if (modalSessionDelete?.id) {
      deleteSession({ id: modalSessionDelete.id, userId }).then(() => {
        getDataSessions();
        setModalSessionDelete({ show: false, id: false });
      });
    }
  };

  useLayoutEffect(() => {
    getData();
    getDataSessions();
    getDataHistories();
  }, [userId]);

  const onSubmit = useCallback((data) => {
    editUser(data)
      .then(() => NotificationManager.success("Данные успешно обновлены"))
      .catch((err) =>
        NotificationManager.error(
          err?.response?.data?.error ?? "Ошибка при сохранении"
        )
      );
  }, []);

  const onSubmitBlocked = useCallback(
    (data) => {
      blockedUser(data)
        .then(() => {
          NotificationManager.success(
            form.status === 0
              ? "Пользователь разблокирован"
              : "Пользователь заблокирован"
          );
          getData();
          form.status !== 0 && setBlockedShow(false);
        })
        .catch((err) =>
          NotificationManager.error(
            err?.response?.data?.error ?? "Ошибка при сохранении"
          )
        );
    },
    [form]
  );

  if (loading) {
    return <Loader full />;
  }

  if (!form?.id) {
    return (
      <>
        <Meta title="Редактировать пользователя" />
        <Info>
          <svg
            className="mb-3"
            width="60"
            height="60"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.32"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
              fill="#999"
            />
            <path
              d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
              fill="#999"
            />
          </svg>
          <h3>Такого пользователя не существует</h3>
        </Info>
      </>
    );
  }

  return (
    <>
      <Meta title="Редактировать пользователя" />
      <div>
        <Link
          to="/users"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к
          пользователям
        </Link>
      </div>
      {/* <Tabs defaultActiveKey="edit" className="custom-tabs mb-3" fill>
        <Tab eventKey="edit" title="Профиль"> */}
      <Row className="mb-2 align-items-justify">
        <Col md={4}>
          <Card className="mb-3" body>
            <div className="d-flex align-items-start mb-3">
              {/* <div className="pe-3 mt-1">
                    <div className="position-relative">
                      {(form?.status === 0 || form?.blockedEnd) && (
                        <div className="blocked-avatar">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.32"
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              fill="#FF0000"
                              d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
                            />
                            <path
                              fill="#FF0000"
                              d="M18.6038 3.01136C19.2189 2.39628 20.2161 2.39628 20.8312 3.01136C21.4463 3.62643 21.4463 4.62367 20.8312 5.23874L5.23947 20.8304C4.6244 21.4455 3.62716 21.4455 3.01209 20.8304C2.39701 20.2154 2.39701 19.2181 3.01209 18.6031L18.6038 3.01136Z"
                            />
                          </svg>
                        </div>
                      )}
                      {form.media && (
                        <img
                          src={getImageURL({ path: form.media, type: "user" })}
                          width={45}
                          height={45}
                        />
                      )}
                    </div>
                  </div> */}
              <div className="w-100 d-flex justify-content-between">
                <div>
                  {/* <p className="fw-6">{form?.nickname ?? "Не указано"}</p> */}
                  <p className="text-muted fs-08">
                    Зарегистирован{" "}
                    {moment(form?.createdAt).format("DD MMMM YYYY kk:mm") ??
                      "Email не указан"}
                  </p>
                  <p className="text-primary fs-08">ID - {form?.id}</p>
                </div>
                {/* <div className="d-flex flex-column justify-content-between align-items-end">
                      <span className="fw-6 d-flex align-items-center fs-09">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M15.5237 19.0041C15.3637 19.0048 15.206 18.967 15.0637 18.8941L9.96366 16.2241L4.86366 18.8941C4.52579 19.0718 4.11625 19.0416 3.80808 18.8163C3.49992 18.591 3.34689 18.21 3.41366 17.8341L4.41366 12.2041L0.293656 8.20411C0.0317118 7.94271 -0.0644601 7.55802 0.0436555 7.20411C0.1619 6.84153 0.476078 6.57778 0.853656 6.52411L6.55366 5.69411L9.06366 0.56411C9.23074 0.21912 9.58033 0 9.96366 0C10.347 0 10.6966 0.21912 10.8637 0.56411L13.4037 5.68411L19.1037 6.51411C19.4812 6.56778 19.7954 6.83153 19.9137 7.19411C20.0218 7.54802 19.9256 7.93271 19.6637 8.19411L15.5437 12.1941L16.5437 17.8241C16.6165 18.2069 16.4604 18.5972 16.1437 18.8241C15.9626 18.951 15.7445 19.0143 15.5237 19.0041Z"
                            fill="#ffc107"
                          />
                        </svg>
                        &nbsp;{form?.rating > 0 ? parseFloat(form.rating).toFixed(1) : 0}
                      </span>
                    </div> */}
              </div>
            </div>
            <div className="mb-3">
              <Input
                className="mb-3"
                label="Фамилия"
                name="lastName"
                placeholder="Введите фамилию (Необязательно)"
                errors={errors}
                register={register}
                validation={{
                  maxLength: {
                    value: 50,
                    message: "Максимально 50 символов",
                  },
                }}
              />
              <Input
                className="mb-3"
                label="Имя"
                name="firstName"
                errors={errors}
                register={register}
                placeholder="Введите имя"
                validation={{
                  required: "Введите имя",
                  maxLength: {
                    value: 20,
                    message: "Максимально 20 символов",
                  },
                }}
              />
              <Input
                label="Отчество"
                name="patronymic"
                errors={errors}
                register={register}
                placeholder="Введите отчество (Необязательно)"
                validation={{
                  maxLength: {
                    value: 50,
                    message: "Максимально 50 символов",
                  },
                }}
              />
            </div>
            <Row>
              <Col md={7}>
                <div className="mb-3">
                  <Input
                    readOnly={false}
                    label="Номер телефона"
                    name="phone"
                    errors={errors}
                    defaultValue={form?.phone}
                    register={register}
                  />
                </div>
                <div className="mb-3">
                  <Input
                    label="Адрес"
                    name="about"
                    placeholder="Введите адрес"
                    errors={errors}
                    register={register}
                  />
                </div>
                <div className="mb-3">
                  <Select
                    className="mb-4"
                    label="Пол"
                    onClick={(e) => setValue("sex", e.value)}
                    value={form.sex ?? ""}
                    data={[
                      { title: "Не указано", value: "" },
                      { title: "Мужской", value: "man" },
                      { title: "Женский", value: "woman" },
                    ]}
                  />
                </div>
              </Col>

              {/* <Col>
                    <Form.Check
                      className="mb-3 ps-0"
                      label=" Подтвержденный пользователь"
                      type="checkbox"
                      name="options.verified"
                      id="verified"
                      defaultChecked={form?.options?.verified}
                      {...register("options.verified")}
                    />
                  </Col> */}
            </Row>
            {/* {form?.blockedEnd && (
                  <p className="text-danger mb-3 fs-09">
                    Блокировка до {moment(form.blockedEnd).format("DD.MM.YYYY")}
                  </p>
                )} */}
            <Row>
              <Col md={6}>
                <Button
                  className="btn btn-primary w-100"
                  disabled={!isValid}
                  onClick={handleSubmit(onSubmit)}
                >
                  Сохранить
                </Button>
              </Col>
              {/* <Col md={6}>
                    {form?.status === 0 || form?.blockedEnd ? (
                      <Button
                        className="btn btn-primary w-100"
                        onClick={handleSubmitBlocked(onSubmitBlocked)}
                      >
                        Разблокировать
                      </Button>
                    ) : (
                      <Button
                        className="btn btn-danger w-100"
                        onClick={() => setBlockedShow(true)}
                      >
                        Заблокировать
                      </Button>
                    )}
                  </Col> */}
            </Row>
          </Card>
        </Col>
        <Col md={8}>
          <section className="box">
            <DataTable
              columns={userColumns}
              onChange={(items) => setSelected(items)}
              data={form?.items}
              header={header}
              selectable
              pagination={form?.pagination}
            />
          </section>
        </Col>
      </Row>

      <CustomModal
        title="Блокировка пользователя"
        show={blockedShow}
        setShow={(e) => setBlockedShow(e)}
        footer={
          <>
            <Button className=" me-3" onClick={() => setBlockedShow(false)}>
              Отмена
            </Button>
            <Button
              className="btn-danger"
              onClick={handleSubmitBlocked(onSubmitBlocked)}
            >
              Заблокировать
            </Button>
          </>
        }
      >
        <Input
          label="Окончание блокировки"
          type="datetime-local"
          name="blockedEnd"
          errors={errorsBlocked}
          defaultValue={form.nickname}
          register={registerBlocked}
        />
      </CustomModal>
      {/* </Tab>

      </Tabs> */}
    </>
  );
};

export default EditUser;
