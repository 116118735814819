import React from 'react';
import DoorMain from './DoorMain';

function PartitionMain({ partitionWidth, partitionHeight, numDoors, handlePositions, handleHeight, topBottomBarHeight, plusProf, maxWidth, maxHeight, door, lineCount, colorProf, colorLine }) {
  let oneDoor = false
  if (numDoors == 1) {
    numDoors = 3;
    oneDoor = true
  }
  // Вычисление масштабного коэффициента
  const sideRectWidth = partitionWidth / numDoors;
  const grayBorderSize = 30

  // Вычисление масштабного коэффициента с учетом боковых прямоугольников
  const maxScaleX = maxWidth / parseInt(partitionWidth);
  const maxScaleY = maxHeight / partitionHeight;
  const maxScale = Math.min(maxScaleX, maxScaleY);

  const doorWidth = partitionWidth / numDoors;
  const doorHeight = partitionHeight - 2 * topBottomBarHeight;

  const scaledDoorWidth = doorWidth * maxScale;
  const scaledDoorHeight = doorHeight * maxScale;
  const scaledHandleHeight = handleHeight * maxScale;
  const scaledHandleWidth = 5 * 10 * maxScale;
  const scaledTopBottomBarHeight = topBottomBarHeight * maxScale
  const scaledGrayBorderSize = grayBorderSize * maxScale

  const totalDoorsWidth = numDoors * scaledDoorWidth;
  const xOffset = (maxWidth - totalDoorsWidth) / 2; // Центрирование дверей
  let val = [0, 0, 0, 0]
  let xval = [0, 0, 0, 0]
  if (lineCount > 1) {
    if (numDoors == 2) {
      val[1] = scaledGrayBorderSize / 2 + 2
      xval[1] = scaledGrayBorderSize / 2 + 2
    }
    if (numDoors == 3) {
      val[1] = scaledGrayBorderSize * 2 / 3 + 2
      xval[1] = scaledGrayBorderSize * 2 / 3 + 2
      val[2] = scaledGrayBorderSize * 2 / 3 + 2
      xval[2] = scaledGrayBorderSize * 2 / 3 + 2
    }
    if (numDoors == 4) {
      val[0] = scaledGrayBorderSize / 2
      val[1] = scaledGrayBorderSize / 2
      val[2] = scaledGrayBorderSize / 2
      val[3] = scaledGrayBorderSize / 2
      xval[1] = scaledGrayBorderSize / 2
      xval[2] = 0
      xval[3] = scaledGrayBorderSize / 2
    }
  }


  const doors = Array.from({ length: numDoors }, (_, i) => (
    <DoorMain
      oneDoor={oneDoor}
      colorProf={colorProf}
      i={i}
      key={i}
      x={xOffset + i * (scaledDoorWidth) - xval[i]}
      y={scaledTopBottomBarHeight}
      width={scaledDoorWidth + val[i]}
      height={scaledDoorHeight}
      handlePosition={handlePositions && handlePositions[i]}
      handleHeight={scaledHandleHeight}
      handleWidth={scaledHandleWidth}
      grayBorderSize={scaledGrayBorderSize}
    />

  ));

  if (doors.length > 0) {
    return (
      <div className="main-svg">
        <svg width={maxWidth} height={scaledDoorHeight + 2 * scaledTopBottomBarHeight + 40} viewBox={`0 0 ${maxWidth} ${scaledDoorHeight + 2 * scaledTopBottomBarHeight + 40}`}>
          <defs>
            <pattern id="lightHatch" patternUnits="userSpaceOnUse" width="20" height="20">
              <line x1="0" y1="0" x2="10" y2="10" stroke="lightgray" strokeWidth="1" />
            </pattern>
          </defs>
          {oneDoor ?
            <>
              {/* Верхняя полоса */}
              < rect x={xOffset + scaledDoorWidth} y="0" width={scaledDoorWidth} height={scaledTopBottomBarHeight} fill={colorLine ?? "gray"} stroke='black' />
              {/* Нижняя полоса */}
              <rect x={xOffset + scaledDoorWidth} y={scaledTopBottomBarHeight + scaledDoorHeight} width={scaledDoorWidth} height={scaledTopBottomBarHeight} fill={colorLine ?? "gray"} stroke='black' />
            </>
            :
            <>
              {/* Верхняя полоса */}
              < rect x={xOffset} y="0" width={totalDoorsWidth} height={scaledTopBottomBarHeight} fill={colorLine ?? "gray"} stroke='black' />
              {/* Нижняя полоса */}
              <rect x={xOffset} y={scaledTopBottomBarHeight + scaledDoorHeight} width={totalDoorsWidth} height={scaledTopBottomBarHeight} fill={colorLine ?? "gray"} stroke='black' />
            </>
          }

          {/* <rect x={0} y={0} width={maxWidth} height={scaledDoorHeight + 2 * scaledTopBottomBarHeight} fill="red" style={{ filter: 'opacity(0.4)' }} /> */}

          {doors}



        </svg>
      </div>
    );
  }
}

export default PartitionMain;
