import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row
} from "react-bootstrap";
import Meta from "../../components/Meta";
import Loader from "../../components/UI/Loader";
import { useForm, useWatch } from "react-hook-form";
import Select from "../../components/UI/Select";
import { getCategories, getCategory } from "../../services/category";
import { getImageURL } from "../../helpers/image";
import Input from "../../components/UI/Input";
import Partition from "../../components/Partition";
import CustomModal from "../../components/utils/CustomModal";
import PartitionTop from "../../components/PartitionTop";
import { NotificationManager } from "react-notifications";
import useIsMobile from "../../hooks/useIsMobile";
import PartitionMain from "../../components/PartitionMain";




const EditCategory = () => {
  const [loading, setLoading] = useState(false);
  const [catalog, setCatalog] = useState({ items: [], loading: true });
  const [calc, setCalc] = useState({});
  const [show, setShow] = useState({
    show: false,
    data: false,
  });
  const [showParam, setShowParam] = useState({
    show: false,
    data: false,
  });
  const mirrorStates = [0, 1, 2, 3]; // Возможные состояния
  const [mirrorState, setMirrorState] = useState(0);

  const handleMirrorToggle = () => {
    setMirrorState((prevState) => {
      const nextIndex = (prevState + 1) % mirrorStates.length; // Циклическое переключение
      return mirrorStates[nextIndex];
    });
  };
  const isMobileXL = useIsMobile("1639px");
  const isMobileLG = useIsMobile("1530px");
  const isMobileMD = useIsMobile("1440px");
  const isMobileSM = useIsMobile("1300px");
  const isMobileXS = useIsMobile("1100px");
  const maxWidth = isMobileXS ? 350 : isMobileSM ? 400 : isMobileMD ? 500 : isMobileLG ? 550 : isMobileXL ? 615 : 700;
  const maxWidthMain = isMobileXS ? 225 : isMobileSM ? 256 : isMobileMD ? 319 : isMobileLG ? 370 : isMobileXL ? 391 : 409;
  const maxHeight = maxWidth / 2;
  const [selectedItemId, setSelectedItemId] = useState(null);

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });


  const getData = useCallback(() => {
    getCategories()
      .then((res) => {
        setCatalog((prev) => ({
          ...prev,
          items: res,
          loading: false
        }));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false)
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при загрузке"
        );
      });
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const data = useWatch({ control });
  useEffect(() => {
    const calculatePrice = (paramIndex, multiplier, defaultValue = 0) => {
      const param = data?.param?.[paramIndex];
      return param ? param.price * multiplier : defaultValue;
    };

    if (data.param) {
      const height = data.height >= 2000 ? data.height : data?.param[5]?.data?.defaultHeight ?? 0;
      const width = data.width >= 400 ? data.width : data?.param[5]?.data?.dafaultWidth ?? 0;
      const doorCount = data?.param[5]?.data?.doorCount ?? 0;
      const lineCount = data?.param[5]?.data?.lineCount ?? 0;
      const rolCount = data?.param[9]?.data?.rolCount ?? 0;
      const plusProf = parseInt(data?.param[5]?.data?.plusProf) ?? 0;
      const barHeight = parseInt(data?.param[6]?.data?.height) ?? 0;

      const doorHeight = parseInt(height - 2 * barHeight);
      const doorWidth = parseInt(width / doorCount + plusProf) || 0; // Обработка деления на ноль

      const colorProf = doorCount * (2 * doorHeight + 2 * doorWidth) / 1000 * (data?.param[1]?.data?.workPrice ?? 0)


      const priceProf = calculatePrice(1, doorCount * (2 * doorHeight + 2 * doorWidth) / 1000) + colorProf;

      const priceFasad = calculatePrice(2, doorHeight / 1000 * doorWidth / 1000);
      const priceLine = calculatePrice(8, lineCount * width / 1000);
      const priceRol = calculatePrice(9, rolCount * doorCount);
      const priceDekor = calculatePrice(3, doorHeight / 1000 * doorWidth / 1000);

      let totalHandles = 0;
      (data.door || []).forEach(doorType => {
        switch (doorType) {
          case 'left':
          case 'right':
            totalHandles++;
            break;
          case 'two':
            totalHandles += 2;
            break;
        }
      });

      const priceHand = calculatePrice(4, totalHandles);
      const total = Math.ceil(priceProf + priceFasad + priceLine + priceRol + priceDekor + priceHand);

      setCalc({
        ...data,
        height,
        width,
        doorHeight,
        doorWidth,
        colorProf,
        priceProf,
        priceFasad,
        priceLine,
        priceRol,
        priceDekor,
        priceHand,
        total,
      });
    }
  }, [data]);
  const onShow = useCallback((item) => {
    if (!data?.param || !data?.param[6]) {
      return NotificationManager.error("Выберите тип крепления");
    }



    let filtredItem = { ...item }; // Создаем копию, чтобы не мутировать исходный объект
    filtredItem.params = (data?.param[6] && data?.param[6].id) ? // Проверка на существование id
      filtredItem.params
        .filter(e => e.data.type.includes(data.param[6].id))
        .sort((a, b) => a.priority - b.priority)
      : []; // Если id не существует, params будет пустым массивом.

    if (filtredItem.params.length === 0) { // Исправлено: проверка длины params
      return NotificationManager.error("Для выбранного типа крепления отсутствуют схемы");
    }
    if (data.param[5]) {
      const index = filtredItem.params.findIndex(item => item.id === data.param[5].id);
      setSelectedItemId(index === -1 ? 0 : index);
    } else {
      setSelectedItemId(0);
    }
    setShow({ show: true, data: filtredItem }); // Отправляем только params
  }, [data, setSelectedItemId, setShow]); // param добавлен в зависимости, если setValue использует param
  const onParam = useCallback((item) => {

    setValue(`param.${item.categoryId}`, item)
    if (item.categoryId === 6) {
      setValue(`param.${5}`, null)// Обновление элемента по индексу
    }
  }, [data, setValue, reset]); // param добавлен в зависимости, если setValue использует param

  const onShowParam = useCallback((item) => {
    const sortedParams = [...item.params].sort((a, b) => a.priority - b.priority);
    const params = { ...item, params: sortedParams }; // Используем отсортированный массив

    let selectedIndex = -1; // Инициализируем, чтобы избежать проблем с undefined

    // Проверяем наличие data.param[item.categoryId] и его корректность
    if (data.param && data.param[item.id]) {
      selectedIndex = sortedParams.findIndex(paramItem => paramItem.id === data.param[item.id].id);
    }

    setSelectedItemId(selectedIndex === -1 ? 0 : selectedIndex); // Используем null вместо 0
    setShowParam({ show: true, data: params });
  }, [data, setShowParam, setSelectedItemId]);

  // console.log(data)
  if (loading) {
    return <Loader full />;
  }
  return (

    <section className="box w-100">
      <Meta title="Конфигуратор" />
      <div className="config">
        <div className="left-column">
          <div className="main-img" >
            <img src={(data?.param && data?.param[2]?.largeMedia) ? getImageURL({ path: data?.param[2]?.largeMedia, type: "param", size: "max" }) : "/images/main.webp"} alt="" />
            {data?.param && data?.param[5]?.data?.doorCount && data?.param[5]?.data?.doorCount == 1 &&
              <div>
                <div className="left">
                  <img src={"/images/wallLeft.jpg"} alt="" />
                </div>
                <div className="right">
                  <img src={"/images/wallRight.jpg"} alt="" />
                </div>
              </div>}
            <PartitionMain
              maxWidth={maxWidthMain}
              maxHeight={maxWidthMain}
              plusProf={data?.param && data?.param[5]?.data?.plusProf}
              topBottomBarHeight={data?.param && (data?.param[6]?.data?.height ?? 0)}
              door={data?.param && data?.param[5]?.data?.door}
              partitionWidth={2600}
              partitionHeight={2600}
              numDoors={data?.param && data?.param[5]?.data?.doorCount}
              handlePositions={data.door} handleHeight={data?.param && data?.param[4]?.data?.heightHand}
              lineCount={data?.param && data?.param[5]?.data?.lineCount}
              colorProf={data?.param && data?.param[1]?.data?.color}
              colorLine={data?.param && data?.param[8]?.data?.color}
            />
          </div>


          {catalog.items && catalog.items.length > 0 && catalog.items.filter(e => e.id < 5).map((item, i) =>
            <Row className="align-items-center mb-3">
              <Col md={4} >
                <p>{item.title}</p>
              </Col>
              <Col md={4} >
                <Button className={"w-100"} onClick={() =>
                  onShowParam(item)
                }>{(data?.param && data?.param[item.id] && data?.param[item.id].title) ?? "Выбрать"}</Button>
              </Col>
              <Col md={4} className="mini">
                <img src={data.param && data.param[item.id] && getImageURL({ path: data.param[item.id]?.media, type: "param" })} alt="" />
              </Col>
            </Row>

          )}
          {data?.param && data?.param[5]?.data?.doorCount && data?.param[4]?.data?.heightHand &&
            <>
              <p className="mb-3">Расположение ручек</p>
              {Array.from({ length: data?.param[5]?.data?.doorCount }, (_, index) => {
                const handleCheckChange = (e, field) => {
                  const updatedDoor = [...(data.door || [])];
                  updatedDoor[index] = { ...updatedDoor[index] };

                  // Устанавливаем значение выбранного поля
                  updatedDoor[index][field] = e.target.checked;


                  // Если выбираем "Слева" или "Справа", сбрасываем "Без ручек"
                  if (field === 'left' || field === 'right') {
                    updatedDoor[index].without = false;
                  }

                  // Если выбираем "Без ручек", сбрасываем "Слева" и "Справа"
                  if (field === 'without' && e.target.checked) {
                    updatedDoor[index].left = false;
                    updatedDoor[index].right = false;
                  }

                  setValue('door', updatedDoor);
                };

                return (
                  <Form key={index} className="d-flex justify-content-between mb-3">
                    <p>Дверь №{index + 1}</p>
                    <Form.Check
                      label="Слева"
                      type="checkbox"
                      checked={data.door && data.door[index] && data.door[index].left}
                      onChange={(e) => handleCheckChange(e, 'left')}
                    />
                    <Form.Check
                      label="Справа"
                      type="checkbox"
                      checked={data.door && data.door[index] && data.door[index].right}
                      onChange={(e) => handleCheckChange(e, 'right')}
                    />
                    <Form.Check
                      label="Без ручек"
                      type="checkbox"
                      checked={data.door && data.door[index] && data.door[index].without}
                      onChange={(e) => handleCheckChange(e, 'without')}
                    />
                  </Form>
                );
              })}


            </>
          }
          {data?.param && data?.param[5]?.data?.doorCount &&
            <>
              <p className="mb-3">Комплекты роликов: количество(комплектов) {data?.param[5]?.data?.doorCount}</p>
              {Array.from({ length: data?.param[5]?.data?.doorCount }, (_, index) => (
                <Row key={index} className="d-flex juxtify-content-between mb-3">
                  <Col md={6}> <p>Дверь №{index + 1}</p></Col>
                  <Col md={6}>
                    <Select
                      value={data.rol && data.rol[index]?.id}
                      title="Выбрать"
                      // label={catalog.items.find(e => e.id == 9).title}
                      onClick={(e) => setValue(`rol.${index}`, e.data)}
                      data={catalog.items.find(e => e.id == 9)?.params.map((e) => ({ value: e.id, data: e, title: e.title }))}
                    />
                  </Col>
                </ Row>
              ))}
            </>
          }
          {catalog.items && catalog.items.length > 0 && catalog.items.filter(e => e.id == 8).map((item, i) =>

            <Row className="align-items-center mb-3">
              <Col md={6} >
                <p>{item.title}</p>
              </Col>
              <Col md={6}>
                <Select
                  value={data.param && data.param[item.id]?.id}
                  title="Выбрать"
                  // label={item.title}
                  onClick={(e) => {
                    onParam(e.data)
                  }}
                  data={item.params.map((e) => ({ value: e.id, data: e, title: e.title }))}
                />
              </Col>
            </Row>
          )}

        </div>
        <div className="right-column">
          <div className="w-100">
            <Row className="align-items-center mb-3">
              {catalog.items && catalog.items.length > 0 && catalog.items.filter(e => e.id == 6).map((item, i) =>

                <Col md={4}>

                  <Button onClick={() =>
                    onShowParam(item)
                  }>{(data?.param && data?.param[item.id] && data?.param[item.id].title) ?? item.title}</Button>
                </Col>
              )}
              {catalog.items && catalog.items.length > 0 && catalog.items.filter(e => e.id == 5).map((item, i) =>
                <Col md={4}>

                  <Button onClick={() =>
                    onShow(item)
                  }>{(data?.param && data?.param[item.id] && "Схема " + data?.param[item.id].title) ?? item.title}</Button>
                </Col>
              )}
              <Col md={4}>
                <Button >
                  Количество вставок
                </Button>
              </Col>
            </Row>
            <div className="size">
              <p className="mt-3 me-3">Размеры проема</p>
              <div className="d-flex mt-3">
                <Input
                  label="Ширина"
                  name="width"
                  value={data.width}
                  register={register}
                  placeholder="0"
                  type="number" // Устанавливаем тип поля как число
                />

                <Input
                  className="ms-3"
                  label="Высота"
                  defaultValue={data.height}
                  name="height"
                  register={register}
                  placeholder="0"
                />
              </div>
            </div>

            <div className="mt-3  " >
              {/* <img src="/images/glass.webp" alt="" /> */}
              <Partition
                maxWidth={maxWidth}
                maxHeight={maxHeight}
                title={data?.param && data?.param[2]?.title}
                plusProf={data?.param && data?.param[5]?.data?.plusProf}
                topBottomBarHeight={data?.param && (data?.param[6]?.data?.height ?? 0)}
                partitionWidth={calc.width}
                partitionHeight={calc.height}
                numDoors={data?.param && data?.param[5]?.data?.doorCount}
                handlePositions={data.door} handleHeight={data?.param && data?.param[4]?.data?.heightHand}
              />

            </div>
            <div className="size">
              <p className="mt-3 me-3">Размеры полотен</p>
              <div className="d-flex mt-3">
                <Input
                  label="Ширина"
                  name="width"
                  value={calc.doorWidth}
                  // register={register}
                  placeholder="0"
                  type="number" // Устанавливаем тип поля как число
                />

                <Input
                  className="ms-3"
                  label="Высота"
                  value={calc.doorHeight}
                  name="height"
                  // register={register}
                  placeholder="0"
                />
              </div>
            </div>

            <div className="mt-3 mb-4 " >
              {/* <img src="/images/glass.webp" alt="" /> */}
              <PartitionTop
                maxWidth={maxWidth}
                maxHeight={200}
                partitionWidth={calc.width}
                partitionHeight={calc.height}
                numDoors={data?.param && data?.param[5]?.data?.doorCount}
                wallSize={data?.param && data?.param[5]?.data?.wallSize}
                wallType={data?.param && data?.param[5]?.data?.wallType}
                door={data?.param && data?.param[5]?.data?.door}
                plusProf={data?.param && data?.param[5]?.data?.plusProf}
                lineCount={data?.param && data?.param[5]?.data?.lineCount}
                mirrorState={mirrorState}
              />

            </div>
            <Row className="align-items-center mb-3">
              <Col mb={4}>
                <Button>
                  Схема крепления
                </Button>
              </Col>
              <Col mb={4}>
                <Button >
                  Схема монтажа
                </Button>
              </Col>
              <Col mb={4}>
                <Button onClick={handleMirrorToggle}>
                  Развернуть
                </Button>
              </Col>



            </Row>
          </div >
        </div>
      </div>
      <Col md={5}>
        <p className="mt-3">Метод рассчета(требуется уточнение)</p>
        <p >Высота двери =  высота перегодки - 2 * высота крепления? в мм(высота направляющих? высота роликов?)  = <b>{calc.doorHeight}</b></p>
        <p>Ширина двери =  ширина перегодки в мм / количество дверей + увеличение профиля  = <b>{calc.doorWidth}</b></p>
        <p>Цена за окраску профиля при наличии = количество дверей * (высота двери *2 + ширина двери *2) / 1000 * цена за окраску одного метра  = <b>{calc.colorProf}</b></p>
        <p>Цена профиля = количество дверей * (высота двери *2 + ширина двери *2) / 1000 * цена за метр  = <b>{calc.priceProf}</b></p>
        <p>Цена фасада(стекло) = количество дверей * высота двери / 1000 * ширина двери /1000 * цена за метр в кв(надо ли убирать ширину профиля?)  = <b>{calc.priceFasad}</b></p>
        <p>Цена направляющих = количестов направляющих по схеме * ширина перегородки / 1000 * цена направляющей = <b>{calc.priceLine}</b></p>
        <p>Цена за ролики = количество роликов на одну дверь * количество дверей * цена ролика = <b>{calc.priceRol}</b></p>
        <p>Цена за декор = ширина двери / 1000 * высоту двери / 1000 *количество двери * цена за метр кв(или надо рассчитывать по штучно?) = <b>{calc.priceDekor}</b></p>
        <p>Цена ручек = количество * цена одной ручки = <b>{calc.priceHand}</b></p>
        <p>Стоимость = цена профиля + цена направляющих + цена фасада + цена роликов + цена декора + Цена ручек(тип крепления? вид перегородки?) = <b>{calc.total}</b></p>
        <Card className="mt-3"><h3>Стоимость = {calc.total}</h3></Card>
      </Col>
      <Row className="mt-3">
        <Button className="me-3">
          Заказать
        </Button>
        <Button >
          Распечатать
        </Button>
      </Row>
      <CustomModal
        title={show.data?.title} // Используйте опциональный цепочечный оператор
        show={show.show}
        dialogClassName={"modal-xl "}
        setShow={(e) => setShow({ show: e, data: e })}
        footer={
          <>
            <Button className="btn-light me-3" onClick={() => setShow({ show: false, data: null })}>
              Отмена
            </Button>
            <Button
              disabled={selectedItemId === null} // Отключить кнопку, если ничего не выбрано
              onClick={() => {
                reset({
                  ...data,
                  width: show.data?.params[selectedItemId]?.data?.dafaultWidth,
                  height: show.data?.params[selectedItemId]?.data?.defaultHeight,
                  param: {
                    ...data.param,
                    [show.data?.id]: show.data?.params[selectedItemId],
                  }
                });
                setShow({ show: false, data: null });
                setSelectedItemId(null); // Сбросить выбор после сохранения
              }}
            >
              Сохранить
            </Button>
          </>
        }
      >
        <div className="d-flex w-100">
          <div className="d-flex row row-cols-1 row-cols-xs-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-3 row-cols-xl-3 gx-4 gy-4">
            {show?.data?.params && show?.data?.params.map((item, index) => ( // Используйте map с индексом
              <Card
                key={item.id} // Убедитесь, что у каждого элемента есть уникальный ключ
                className={`card-selector ${selectedItemId === index ? 'selected' : ''}`} // Добавлено условное имя класса
                onClick={() => setSelectedItemId(index)} // Установите selectedItemId при клике
              >
                <div className="mini">
                  <img src={getImageURL({ path: item?.media, type: "param", size: "max" })} alt="" />
                </div>
                <p>{item.title}</p>
              </Card>
            ))}
          </div>
          <div className="middle-img ">

            <img src={getImageURL({ path: show.data?.params && (show.data?.params[selectedItemId]?.largeMedia ?? show.data?.params[selectedItemId]?.media), type: "param", size: "max" })} alt="" />

          </div>

        </div>

      </CustomModal>

      <CustomModal
        title={showParam.data?.title} // Используйте опциональный цепочечный оператор
        show={showParam.show}
        dialogClassName={"modal-xl "}
        setShow={(e) => setShowParam({ show: e, data: e })}
        footer={
          <>
            <Button className="btn-light me-3" onClick={() => setShowParam({ show: false, data: null })}>
              Отмена
            </Button>
            <Button
              disabled={selectedItemId === null} // Отключить кнопку, если ничего не выбрано
              onClick={() => {
                reset({
                  ...data,
                  param: {
                    ...data.param,
                    [showParam.data?.id]: showParam.data?.params[selectedItemId],
                  }
                });
                setShowParam({ show: false, data: null });
                setSelectedItemId(0); // Сбросить выбор после сохранения
              }}
            >
              Сохранить
            </Button>
          </>
        }
      >
        <div className="d-flex w-100 row row-cols-2 row-cols-lg-1 gx-1 gy-1 gx-lg-4 gy-lg-4">
          {showParam?.data?.params && showParam?.data?.params.map((item, index) => ( // Используйте map с индексом
            <Card
              key={index} // Убедитесь, что у каждого элемента есть уникальный ключ
              className={`card-selector-param ${selectedItemId === index ? 'selected' : ''}`} // Добавлено условное имя класса
              onClick={() => setSelectedItemId(index)} // Установите selectedItemId при клике
            >
              <div className="mini">
                <img src={getImageURL({ path: item?.media, type: "param", size: "max" })} alt="" />
              </div>
              <p>{item.title}</p>
            </Card>
          ))}


        </div>

      </CustomModal>
    </section >
  );
};

export default EditCategory;
