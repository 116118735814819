import React from 'react';
import useIsMobile from '../hooks/useIsMobile';
import Door from './Door';

function Partition({ partitionWidth, partitionHeight, numDoors, handlePositions, handleHeight, topBottomBarHeight, plusProf, maxWidth, maxHeight, title }) {
  const isMobileSM = useIsMobile("1300px");
  const isMobileXS = useIsMobile("1100px");
  const fontSize = isMobileSM ? isMobileXS ? 10 : 12 : 14
  // Вычисление масштабного коэффициента
  const sideRectWidth = partitionWidth / numDoors;
  const grayBorderSize = 30

  // Вычисление масштабного коэффициента с учетом боковых прямоугольников
  const maxScaleX = maxWidth / (parseInt(partitionWidth) + 2 * sideRectWidth);
  const maxScaleY = maxHeight / partitionHeight;
  const maxScale = Math.min(maxScaleX, maxScaleY);

  const doorWidth = partitionWidth / numDoors;
  const doorHeight = partitionHeight - 2 * topBottomBarHeight;

  const scaledDoorWidth = doorWidth * maxScale;
  const scaledDoorHeight = doorHeight * maxScale;
  const scaledHandleHeight = handleHeight * maxScale;
  const scaledHandleWidth = 5 * 10 * maxScale;
  const scaledTopBottomBarHeight = topBottomBarHeight * maxScale
  const scaledGrayBorderSize = grayBorderSize * maxScale

  const totalDoorsWidth = numDoors * scaledDoorWidth;
  const xOffset = (maxWidth - totalDoorsWidth) / 2; // Центрирование дверей




  const doors = Array.from({ length: numDoors }, (_, i) => (
    <Door
      key={i}
      x={xOffset + i * (scaledDoorWidth)}
      y={scaledTopBottomBarHeight}
      width={scaledDoorWidth}
      height={scaledDoorHeight}
      handlePosition={handlePositions && handlePositions[i]}
      handleHeight={scaledHandleHeight}
      handleWidth={scaledHandleWidth}
      grayBorderSize={scaledGrayBorderSize}
      title={title}
    />

  ));

  if (doors.length > 0) {
    return (
      <div className="container">
        <svg width={maxWidth} height={scaledDoorHeight + 2 * scaledTopBottomBarHeight + 40} viewBox={`0 0 ${maxWidth} ${scaledDoorHeight + 2 * scaledTopBottomBarHeight + 40}`}>
          <defs>
            <pattern id="lightHatch" patternUnits="userSpaceOnUse" width="20" height="20">
              <line x1="0" y1="0" x2="10" y2="10" stroke="lightgray" strokeWidth="1" />
            </pattern>
          </defs>
          <rect x={0} y={0} width={maxWidth} height={scaledDoorHeight + 2 * scaledTopBottomBarHeight} fill="url(#lightHatch)" />
          {/* Верхняя полоса */}
          <rect x={xOffset} y="0" width={totalDoorsWidth} height={scaledTopBottomBarHeight} fill="gray" stroke='black' />

          <line x1={xOffset - 20} y1={scaledTopBottomBarHeight} x2={xOffset - 20} y2={scaledDoorHeight} stroke="black" strokeWidth="2" />
          <text x={xOffset - scaledDoorWidth + 20} y={scaledDoorHeight / 2 + scaledTopBottomBarHeight} textAnchor="start" dominantBaseline="middle">
            {doorHeight}
          </text>


          <line x1={xOffset + totalDoorsWidth + 20} y1={0} x2={xOffset + totalDoorsWidth + 20} y2={scaledDoorHeight + 2 * scaledTopBottomBarHeight} stroke="black" strokeWidth="2" />
          <text x={xOffset + totalDoorsWidth + 20 + 10} y={scaledDoorHeight / 2 + scaledTopBottomBarHeight} textAnchor="start" dominantBaseline="middle">
            {partitionHeight}
          </text>

          {doors}

          {/* Нижняя полоса */}
          <rect x={xOffset} y={scaledTopBottomBarHeight + scaledDoorHeight} width={totalDoorsWidth} height={scaledTopBottomBarHeight} fill="gray" stroke='black' />

        </svg>
      </div>
    );
  }
}

export default Partition;
