import React from 'react';

const DoorTop = ({ x, y, width, height, grayStripeWidth, lineWidth = 1, vector = 'right', doorWidth, mirrorState }) => {
  const Arrow = () => {
    const arrowLength = 10;
    const arrowWidth = 10;
    const lineY = 20;

    // Координаты для линии
    const lineX1 = 2 * grayStripeWidth;
    const lineX2 = width - 2 * grayStripeWidth;

    // Базовая точка для стрелки
    const baseXLeft = lineX1 + 2 * grayStripeWidth;
    const baseXRight = lineX2 - 2 * grayStripeWidth;

    // Координаты для левой стрелки
    const arrowTipXLeft = baseXLeft - arrowLength - grayStripeWidth;
    const arrowTipYLeft = lineY;
    const arrowLeftXLeft = arrowTipXLeft + arrowWidth;
    const arrowLeftYLeft = lineY - arrowWidth / 2;
    const arrowRightXLeft = arrowTipXLeft + arrowWidth;
    const arrowRightYLeft = lineY + arrowWidth / 2;

    // Координаты для правой стрелки
    const arrowTipXRight = baseXRight + arrowLength + grayStripeWidth;
    const arrowTipYRight = lineY;
    const arrowLeftXRight = arrowTipXRight - arrowWidth;
    const arrowLeftYRight = lineY - arrowWidth / 2;
    const arrowRightXRight = arrowTipXRight - arrowWidth;
    const arrowRightYRight = lineY + arrowWidth / 2;

    return (
      <svg>
        <line x1={lineX1} y1={lineY} x2={lineX2} y2={lineY} stroke="black" strokeWidth="2" />
        {vector == "any" ?
          <>
            <polygon
              points={`${arrowLeftXLeft},${arrowLeftYLeft} ${arrowTipXLeft},${arrowTipYLeft} ${arrowRightXLeft},${arrowRightYLeft}`}
              fill="black"
            />
            <polygon
              points={`${arrowLeftXRight},${arrowLeftYRight} ${arrowTipXRight},${arrowTipYRight} ${arrowRightXRight},${arrowRightYRight}`}
              fill="black"
            />
          </>
          : vector == "left" ?
            mirrorState % 2 == 0 ?
              <polygon
                points={`${arrowLeftXLeft},${arrowLeftYLeft} ${arrowTipXLeft},${arrowTipYLeft} ${arrowRightXLeft},${arrowRightYLeft}`}
                fill="black"
              />
              :
              <polygon
                points={`${arrowLeftXRight},${arrowLeftYRight} ${arrowTipXRight},${arrowTipYRight} ${arrowRightXRight},${arrowRightYRight}`}
                fill="black"
              />
            :
            mirrorState % 2 != 0 ?
              <polygon
                points={`${arrowLeftXLeft},${arrowLeftYLeft} ${arrowTipXLeft},${arrowTipYLeft} ${arrowRightXLeft},${arrowRightYLeft}`}
                fill="black"
              />
              :
              <polygon
                points={`${arrowLeftXRight},${arrowLeftYRight} ${arrowTipXRight},${arrowTipYRight} ${arrowRightXRight},${arrowRightYRight}`}
                fill="black"
              />
        }
        <text x={width / 2} y={lineY + 10} textAnchor="middle" dominantBaseline="middle">
          {parseInt(doorWidth)}
        </text>

      </svg>
    );
  };

  // Проверки на корректность входных данных
  if (grayStripeWidth * 2 >= width || lineWidth >= width) {
    console.error("grayStripeWidth * 2 or lineWidth cannot be greater than or equal to width");
    return null;
  }

  const mainRectWidth = width - 2 * grayStripeWidth;
  const lineYRect = (height - lineWidth) / 2;

  return (
    <g transform={`translate(${x}, ${y})`}>
      {/* Левая серая полоска */}
      <rect x={0} y={0} width={grayStripeWidth} height={height} fill="lightgray" stroke="black" />

      {/* Центральный прямоугольник */}
      <rect x={grayStripeWidth} y={0} width={mainRectWidth} height={height} fill="white" stroke="black" />

      {/* Тонкая линия */}
      <rect x={grayStripeWidth} y={lineYRect} width={mainRectWidth} height={lineWidth} fill="black" />

      {/* Правая серая полоска */}
      <rect x={grayStripeWidth + mainRectWidth} y={0} width={grayStripeWidth} height={height} fill="lightgray" stroke="black" />
      <Arrow />
    </g>
  );
};

export default DoorTop;
