import React from "react";
import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";

const CustomModal = ({
  className = "",
  classNameBody = "",
  classNameFooter = "",
  setShow,
  show = false,
  size,
  classNameHeader,
  title,
  dialogClassName,
  children,
  footer,
}) => {
  const closeModal = () => setShow(false);

  return (
    <Modal
      className={className}
      show={show}
      onHide={closeModal}
      centered
      size={size}
      dialogClassName={dialogClassName}
    >
      <Modal.Header className={classNameHeader}>
        {title ? <h5>{title}</h5> : null}
        <button className="close" onClick={closeModal}>
          <IoClose />
        </button>
      </Modal.Header>
      <Modal.Body className={classNameBody}>{children}</Modal.Body>
      {footer && (
        <Modal.Footer className={classNameFooter}>{footer}</Modal.Footer>
      )}
    </Modal>
  );
};

export default CustomModal;
