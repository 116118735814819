const fasadOptions = [
  {
    value: 1,
    title: "Стекло тонированное",
  },
  {
    value: 2,
    title: "Матовое стекло",
  },
  {
    value: 3,
    title: "Стекло Stopsol (рефлективные) ",
  },
  {
    value: 4,
    title: "Стекло рифленое",
  },
  {
    value: 5,
    title: "Декоративная сетка в триплексе",
  },
];

const isFasadOptions = ({ option }) => {
  let optionInfo = fasadOptions.find((e) => e.value === option);
  if (!optionInfo) {
    return "Опция не найдена";
  }

  return { option: optionInfo };
};

export { fasadOptions, isFasadOptions };
