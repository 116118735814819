import React, { useState } from 'react';

function DoorMain({ x, y, width, height, handlePosition, handleHeight, grayBorderSize, colorProf, oneDoor, i }) {
  const borderWidth = 0; // Ширина черной границы

  const innerWidth = width - 2 * grayBorderSize - 2 * borderWidth;
  const innerHeight = height - 2 * grayBorderSize - 2 * borderWidth;
  const [hovered, setHovered] = useState(false);
  const [selected, setSelected] = useState(false);


  const imagePathLeft = "/images/wallLeft.jpg";
  const imagePathRight = "/images/wallRight.jpg";

  const handleMouseOver = () => setHovered(true);
  const handleMouseOut = () => setHovered(false);
  const handleSelect = () => setSelected(!selected);

  const fillColor = selected ? 'red' : (hovered ? 'pink' : 'white');

  const handles = (() => {
    if (handlePosition === 'two') {
      const handleY = height / 2 - handleHeight / 2;
      return [
        <line
          key="left"
          x1={grayBorderSize}
          y1={handleY}
          x2={grayBorderSize}
          y2={handleY + handleHeight}
          stroke="black"
          strokeWidth="4"
        />,
        <line
          key="right"
          x1={width - grayBorderSize}
          y1={handleY}
          x2={width - grayBorderSize}
          y2={handleY + handleHeight}
          stroke="black"
          strokeWidth="4"
        />,
      ];
    } else if (handlePosition === 'left') {
      // Код для левой ручки остается без изменений
      const handleY = height / 2 - handleHeight / 2;
      return [
        <line
          key="left"
          x1={grayBorderSize}
          y1={handleY}
          x2={grayBorderSize}
          y2={handleY + handleHeight}
          stroke="black"
          strokeWidth="4"
        />,
      ];
    } else if (handlePosition === 'right') {
      // Код для правой ручки остается без изменений
      const handleY = height / 2 - handleHeight / 2;
      return [
        <line
          key="right"
          x1={width - grayBorderSize}
          y1={handleY}
          x2={width - grayBorderSize}
          y2={handleY + handleHeight}
          stroke="black"
          strokeWidth="4"
        />,
      ];
    } else {
      return []; // Или верните null, если handlePosition некорректен
    }
  })();

  return (
    <g transform={`translate(${x}, ${y})`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onClick={handleSelect}>
      <defs>
        <pattern id="glass-pattern" patternUnits="userSpaceOnUse" width="10" height="10">
          <line x1="0" y1="0" x2="10" y2="10" stroke="lightgray" fill="white" strokeWidth="2" strokeDasharray="2 2" />
          <line x1="0" y1="10" x2="10" y2="0" stroke="lightgray" strokeWidth="2" fill="white" strokeDasharray="2 2" />
        </pattern>
      </defs>


      {/* Серый профиль (полностью залитый) */}
      {(oneDoor && (i == 1) || !oneDoor) &&
        <>
          <rect
            x={grayBorderSize + borderWidth}
            y={borderWidth}
            width={width - 2 * grayBorderSize - borderWidth}
            height={grayBorderSize}
            stroke="black"
            // style={{ filter: 'opacity(0.7)' }}
            fill={colorProf ?? "lightgray"}
          />
          <rect
            x={grayBorderSize + borderWidth}
            y={height - grayBorderSize}
            width={width - 2 * grayBorderSize - borderWidth}
            height={grayBorderSize}
            // style={{ filter: 'opacity(0.7)' }}
            stroke="black"
            fill={colorProf ?? "lightgray"}
          />
          <rect
            x={borderWidth}
            y={borderWidth}
            width={grayBorderSize}
            height={height - borderWidth}
            stroke="black"
            fill={colorProf ?? "lightgray"}
          // style={{ filter: 'opacity(0.7)' }}
          />
          <rect
            x={width - grayBorderSize}
            y={borderWidth}
            width={grayBorderSize}
            height={height - borderWidth}
            stroke="black"
            // style={{ filter: 'opacity(0.7)' }}
            fill={colorProf ?? "lightgray"}
          />
        </>
      }

      {/* {handlePosition != "without" && handles} */}
    </g >
  );
}

export default DoorMain;
